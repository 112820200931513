gsap.registerPlugin(ScrollTrigger);

const isMobile = window.innerWidth <= 768;

  const animateIfExists = (selector, animation) => {
    const element = document.querySelector(selector);
    if (element) animation(element);
  };

window.addEventListener('load', () => {
  setTimeout(() => {
    const darkLines = document.querySelectorAll('.top-copy-dark div');
    const lightLines = document.querySelectorAll('.top-copy-light div');
    const paths = document.querySelectorAll('.svg-1, .svg-2, .svg-3, .svg-4, .svg-5, .svg-6, .svg-7, .svg-8');
    const svg11 = document.querySelector('.svg-11');
    const fillPathsBlue = document.querySelectorAll('.svg-9, .svg-10');
    const fillPathsDarkBlue = [...paths, svg11];
    const swiper = document.querySelector('.swiper-container');

    if (!svg11) return;

    const lines = [...darkLines].map((dark, index) => ({
      dark,
      light: lightLines[index] || dark,
    }));

    gsap.set(fillPathsBlue, { fillOpacity: 0 });

    const mainTimeline = gsap.timeline({
      defaults: { duration: 1, ease: "power3.out" },
      paused: true,
    });

    lines.forEach(({ dark, light }, index) => {
      mainTimeline.fromTo([dark, light], { x: -30, opacity: 0 }, { x: 0, opacity: 1 }, `+=${index * 0.2}`);
    });

    mainTimeline.addLabel("startLogoAndSlider", "+=0.2");
    mainTimeline.fromTo(swiper, { opacity: 0 }, { opacity: 1, duration: 0.8 }, "startLogoAndSlider");

    const length11 = svg11.getTotalLength();
    gsap.set(svg11, {
      strokeDasharray: length11,
      strokeDashoffset: length11,
      strokeWidth: 1,
      strokeLinecap: "butt",
    });
    mainTimeline.to(svg11, { strokeDashoffset: 0, duration: 1.2 }, "startLogoAndSlider");

    paths.forEach((path, index) => {
      const length = path.getTotalLength();
      gsap.set(path, {
        strokeDasharray: length,
        strokeDashoffset: length,
        strokeWidth: 0.05,
        strokeLinecap: "butt",
      });
      mainTimeline.to(path, { strokeDashoffset: 0, duration: 1 }, `startLogoAndSlider+=${index * 0.02}`);
    });

    fillPathsDarkBlue.forEach((path, index) => {
      mainTimeline.to(path, { fill: "#013D6F", fillOpacity: 1, duration: 0.02 }, `+=${index * 0.02}`);
    });

    mainTimeline.fromTo(
      fillPathsBlue,
      { scale: 0.85, fill: "transparent", opacity: 0, transformOrigin: "center center" },
      { scale: 1, fill: "#004AB5", fillOpacity: 1, opacity: 1, duration: 1, ease: "power2.out" },
      "+=0.2"
    );

    mainTimeline.to(
      fillPathsBlue,
      { scale: 1.05, duration: 0.5, ease: "sine.inOut", yoyo: true, repeat: 1 },
      "+=0.00001"
    );

    const completeAnimation = () => {
      if (!mainTimeline.isActive()) {
        mainTimeline.progress(1); // アニメーションを即完了
      }
    };

    // ScrollTriggerの設定
    ScrollTrigger.create({
      trigger: ".top-visual",
      start: "top 80%",
      end: "bottom top",
      animation: mainTimeline,
      once: true,
    });

    // ユーザーがファーストビューからスクロールしたらアニメーションを完了
    ScrollTrigger.create({
      trigger: ".top-visual",
      start: "bottom top", // ファーストビューが画面から消えたら
      onEnter: completeAnimation, // アニメーションを即完了
    });

    // ページロード時に要素が既に表示されているか確認し、アニメーションを再生
    if (ScrollTrigger.isInViewport(".top-visual")) {
      mainTimeline.play();
    }

    // ユーザーが素早くスクロールした場合の対策
    window.addEventListener("scroll", () => {
      if (!ScrollTrigger.isInViewport(".top-visual")) {
        completeAnimation(); // スクロール位置に応じて即完了
      }
    });
  }, 2500);



  setTimeout(() => {
    animateAboutAo();
    animateServiceSection();
    animateBlogPage();
    animateCompanyOverview();
    animateFAQ();
    animatePrivacy();
  },2300);
});




document.addEventListener('DOMContentLoaded', function () {

  const animationSettings = {
    opacity: 0,
    y: 30,
    duration: isMobile ? 0.8 : 1, // スマホとPCで異なる速度を設定
    ease: "power3.out",
  };

  // サービスセクションの画像アニメーション
  gsap.fromTo(
    ".service .slide-image-right img", {
      ...animationSettings
    }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".service",
        start: "top 80%",
        end: "bottom 70%",
        scrub: true,
      },
    }
  );

  // サービスセクションのテキストアニメーション
  gsap.fromTo(
    ".service .content-text", {
      ...animationSettings
    }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".service",
        start: "top 80%",
        end: "bottom 60%",
        scrub: true,
      },
    }
  );

  // サービスセクションの背景パララックスアニメーション
  gsap.to(".bg-gray.r-b", {
    y: 100, // 上方向への移動で浮遊感を演出
    ease: "none", // 滑らかな動き
    scrollTrigger: {
      trigger: ".service", // サービスセクション全体をトリガー
      start: "top bottom", // トップが画面に入ったとき
      end: "bottom top", // 底が画面から出るまで
      scrub: true, // スクロールと同期
    },
  });

  // コンセプトセクションの画像アニメーション
  gsap.to(".concept .concept-image img", {
    y: -50, // 大きめの浮遊感
    scale: 1.05, // 少し拡大して動きを強調
    ease: "power1.out", // 滑らかな減速でおしゃれに
    scrollTrigger: {
      trigger: ".concept",
      start: "top 80%", // トップが画面に近づいたとき開始
      end: "bottom 40%", // ボトムが画面から少し出たとき終了
      scrub: 0.5, // 少しゆっくり目の同期
    },
  });

  // コンセプトセクションのテキストアニメーション
  gsap.fromTo(
    ".concept .concept-text", {
      ...animationSettings
    }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".concept",
        start: "top 70%",
        end: "bottom 70%",
        scrub: true,
      },
    }
  );

  // ブログメニューのアニメーション（PCでは短時間で終了）
  gsap.fromTo(
    ".blog-menu", {
      opacity: 1,
      y: 20
    }, {
      opacity: 1,
      y: 0,
      duration: isMobile ? 0.8 : 0.4, // PCでは早めに終了
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".blog-menu",
        start: "top 90%",
        end: "bottom 90%", // PC用に終了を早く
        scrub: true,
      },
    }
  );

  // ブログタイトルのアニメーション
  gsap.fromTo(
    ".blog .title", {
      ...animationSettings
    }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".blog .title",
        start: "top 90%",
        end: "bottom 60%",
        scrub: true,
      },
    }
  );

  // ラージポストの個別アニメーション
  gsap.fromTo(
    ".large-post", {
      ...animationSettings
    }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".large-post",
        start: "top 90%",
        end: "bottom 70%",
        scrub: true,
      },
    }
  );

  // スモールポストの個別アニメーション
  gsap.utils.toArray(".small-post").forEach((post) => {
    gsap.fromTo(
      post, {
        ...animationSettings
      }, {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: post,
          start: "top 95%",
          end: "bottom 75%",
          scrub: true,
        },
      }
    );
  });

  // パンくずリストの背景にパララックス効果を追加
  gsap.to(".pankuzu-bg", {
    y: -40,
    ease: "none",
    scrollTrigger: {
      trigger: ".pankuzu",
      start: "top bottom",
      end: "bottom top",
      scrub: 1,
      // markers: true // デバッグ用マーカーを有効化
    },
  });


  // LINEセクションの背景パララックス効果
  gsap.to(".line .line-bg", {
    y: -30,
    ease: "none",
    scrollTrigger: {
      trigger: ".line",
      start: "top bottom",
      end: "bottom top",
      scrub: true,
      // markers: true // デバッグ用マーカーを有効化
    },
  });

  // LINEセクションのテキストアニメーション
  gsap.fromTo(
    ".line .box-content", {
      ...animationSettings
    }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".line .box-content",
        start: "top 85%",
        end: "bottom 60%",
        scrub: true,
      },
    }
  );

  // Contactセクションの背景パララックス効果
  gsap.to(".contact-bg", {
    y: -140,
    ease: "none",
    scrollTrigger: {
      trigger: ".contact",
      start: "top bottom",
      end: "bottom top",
      scrub: true,
    },
  });

  // Contactセクションのテキストアニメーション
  gsap.fromTo(
    ".contact .box-content", {
      ...animationSettings
    }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".contact .box-content",
        start: "top 85%",
        end: "bottom 60%",
        scrub: true,
      },
    }
  );

  gsap.to(".contact-bg", {
    y: -100, // 背景がゆっくりと上に移動
    scale: 1.05, // わずかに拡大して浮遊感を演出dd
    ease: "power1.out", // スムーズなスクロール感覚
    scrollTrigger: {
      trigger: ".contact",
      start: "top 80%", // 少し早めに開始
      end: "bottom 90%", // フッターに近づく前に終了
      scrub: true,
      onUpdate: (self) => {
        // スクロールがフッターに近づきすぎた場合にパララックスを停止
        if (self.progress > 0.95) {
          self.kill();
        }
      },
    },
  });

  gsap.fromTo(
    ".contact .inner", {
      y: 30,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".contact",
        start: "top 90%",
        end: "bottom 60%",
        scrub: true,
      }
    }
  );

  // ブログカードの個別アニメーション
  gsap.utils.toArray('.related-blogs .swiper-slide').forEach((slide, index) => {
    gsap.fromTo(
      slide, {
        ...animationSettings
      }, {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: slide,
          start: "top 90%",
          end: "bottom 70%",
          scrub: true, // スクロールに同期
        },
      }
    );
  });

  // フッターボタンのフェードイン
  gsap.fromTo(
    ".related-blogs-footer .button", {
      opacity: 0,
      y: 10
    }, {
      opacity: 1,
      y: 0,
      duration: 1.2,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".related-blogs-footer",
        start: "top 90%",
        end: "bottom 80%",
        scrub: true,
      },
    }
  );
});

function animateAboutAo() {
  const commonAnimationSettings = {
    opacity: 0,
    duration: 1.2,
    ease: "power2.out",
  };

  // 1. タイトルのフェード＆スライドイン（ファーストビュー対応）
  animateIfExists(".concept-section .concept-title", (element) => {
    gsap.fromTo(element, { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 1.2, ease: "power2.out" });
  });

  // 2. コンセプト画像のパララックス効果
  animateIfExists(".concept-image-wrapper img", (element) => {
    gsap.fromTo(
      element,
      { y: 80, opacity: 0.8 },
      {
        y: -20,
        opacity: 1,
        ease: "power1.out",
        duration: 1.5,
        scrollTrigger: {
          trigger: ".concept-section",
          start: "top 95%",
          end: "bottom 50%",
          scrub: 0.5,
          // markers: true,
        },
      }
    );
  });

  // 3. コンセプトテキストのフェードイン
  animateIfExists(".concept-description", (element) => {
    gsap.fromTo(
      element.querySelectorAll("h3, p"),
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.3,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: element,
          start: "top 85%",
          end: "bottom 70%",
          scrub: true,
        },
      }
    );
  });

  // 4. 背景画像のパララックス効果
  animateIfExists(".concept-background-image", (element) => {
    gsap.to(element, {
      y: -50,
      scale: 1.1,
      ease: "none",
      scrollTrigger: {
        trigger: ".concept-images",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  });

  // 共通のアニメーション処理
  const animateSection = (descriptionSelector, imageSelector, trigger) => {
    animateIfExists(descriptionSelector, (desc) => {
      gsap.fromTo(
        desc,
        { ...commonAnimationSettings, x: desc.classList.contains("text-left") ? -10 : 10 },
        { opacity: 1, x: 0, scrollTrigger: { trigger, start: "top 80%", end: "top 60%", scrub: true } }
      );
    });

    animateIfExists(imageSelector, (img) => {
      gsap.fromTo(
        img,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, scrollTrigger: { trigger, start: "top 80%", end: "bottom 70%", scrub: true } }
      );
    });
  };

  // 左右セクションのアニメーション適用
  animateSection(
    ".about-section .concept-content.text-left .concept-description",
    ".about-section .concept-content.text-left .concept-image-wrapper",
    ".about-section .concept-content.text-left"
  );

  animateSection(
    ".about-section .concept-content.text-right .concept-description",
    ".about-section .concept-content.text-right .concept-image-wrapper",
    ".about-section .concept-content.text-right"
  );

  // プロフィールテキストのフェードイン
  animateIfExists(".profile-text", (element) => {
    gsap.fromTo(
      element,
      { ...commonAnimationSettings, y: 20 },
      { opacity: 1, y: 0, scrollTrigger: { trigger: element, start: "top 85%", end: "top 65%", scrub: true } }
    );
  });

  // プロフィール画像のフェードインとパララックス効果
  animateIfExists(".profile-image", (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: -30 },
      { opacity: 1, y: 0, duration: 1.8, ease: "power3.out", scrollTrigger: { trigger: element, start: "top 85%", end: "top 65%", scrub: true } }
    );

    gsap.to(element, {
      y: 80,
      ease: "none",
      scrollTrigger: { trigger: ".profile-section", start: "top bottom", end: "bottom top", scrub: true },
    });
  });
}


function animateServiceSection() {

  // サービスセクションのタイトルと説明のフェードイン
  animateIfExists('.service-section h2', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: -20 },
      { opacity: 1, y: 0, duration: 1.2, ease: 'power2.out' }
    );
  });

  animateIfExists('.service-description', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 1.2, delay: 0.3, ease: 'power2.out' }
    );
  });

  // 各サービスアイテムの画像、テキスト、背景のアニメーション
  gsap.utils.toArray('.service-item').forEach((item, index) => {
    const image = item.querySelector('.service-image img');
    const text = item.querySelector('.service-text');
    const bgGray = item.querySelector('.bg-gray');

    // 画像のフェードインと浮遊感
    if (image) {
      gsap.fromTo(
        image,
        { opacity: 0, scale: 0.90 },
        {
          opacity: 1,
          scale: 1,
          y: -20,
          duration: isMobile ? 2 : 1.5,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: item,
            start: 'top 85%',
            end: 'bottom 70%',
            scrub: true,
          },
        }
      );
    }

    // テキストのフェードイン
    if (text) {
      gsap.fromTo(
        text,
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 20,
          duration: 1.2,
          delay: 0.3 * index,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: item,
            start: 'top 85%',
            end: 'bottom 70%',
            scrub: true,
          },
        }
      );
    }

    // 背景のパララックス効果
    if (bgGray) {
      gsap.to(bgGray, {
        y: -123,
        ease: 'none',
        scrollTrigger: {
          trigger: item,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      });
    }
  });
}

function animateBlogPage() {

  // ロゴとタイトルのフェードイン
  animateIfExists('.logo-box h2', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: -20 },
      { opacity: 1, y: 0, duration: 1.2, ease: 'power2.out' }
    );
  });

  // ブログ投稿の各アイテムのアニメーション
  const blogPosts = gsap.utils.toArray('.blog-post');
  blogPosts.forEach((post, index) => {
    gsap.fromTo(
      post,
      { opacity: 0, y: 30 },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: 'power2.out',
        delay: index * 0.1, // 各投稿の遅延
        scrollTrigger: {
          trigger: post,
          start: 'top 95%',
          end: 'bottom 75%',
          scrub: true,
        },
      }
    );
  });

  // READ MOREボタンのアニメーション
  animateIfExists('.read-more', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: 10 },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
          scrub: true,
        },
      }
    );
  });
}

// 会社概要ページのアニメーション
function animateCompanyOverview() {
  // ロゴのフェードイン
  animateIfExists('.company-overview .logo-box h2', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: -20 },
      { opacity: 1, y: 0, duration: 1.2, ease: 'power2.out' }
    );
  });

  // テーブル全体のアニメーション
  animateIfExists('.company-details', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: isMobile ? 10 : 30 },
      {
        opacity: 1,
        y: 0,
        duration: isMobile ? 0.8 : 1.2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: element,
          start: isMobile ? 'top 85%' : 'top 90%',
          scrub: true,
        },
      }
    );
  });

  // 各テーブル行の遅延表示アニメーション
  const tableRows = gsap.utils.toArray('.company-details tr');
  tableRows.forEach((row, index) => {
    gsap.fromTo(
      row,
      { opacity: 0, y: isMobile ? 0 : 20 },
      {
        opacity: 1,
        y: 0,
        duration: isMobile ? 0.6 : 1.2,
        ease: 'power2.out',
        delay: index * (isMobile ? 0.05 : 0.1),
        scrollTrigger: {
          trigger: row,
          start: isMobile ? 'top 95%' : 'top 90%',
          scrub: true,
        },
      }
    );
  });
}

// FAQページのアニメーション
function animateFAQ() {
  // タイトルのフェードイン
  animateIfExists('.faq-section .logo-box h2', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: -20 },
      { opacity: 1, y: 0, duration: 1.2, ease: 'power2.out' }
    );
  });

  // FAQ各項目のアニメーション
  const faqItems = document.querySelectorAll('.faq-item');
  faqItems.forEach((item, index) => {
    gsap.fromTo(
      item,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: isMobile ? 0.6 : 1,
        ease: 'power2.out',
        delay: index * 0.1,
        scrollTrigger: {
          trigger: item,
          start: 'top 95%',
          end: 'bottom 75%',
          scrub: true,
        },
      }
    );
  });
}

// プライバシーポリシーページのアニメーション
function animatePrivacy() {
  // タイトルのフェードイン
  animateIfExists('.privacy-policy-section h2', (element) => {
    gsap.fromTo(
      element,
      { opacity: 0, y: -20 },
      { opacity: 1, y: 0, duration: 1.2, ease: 'power2.out' }
    );
  });

  // 各ポリシー項目のフェードイン
  const policyItems = gsap.utils.toArray('.list-item');
  policyItems.forEach((item, index) => {
    gsap.fromTo(
      item,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: isMobile ? 0.6 : 1.2,
        ease: 'power2.out',
        delay: index * 0.1,
        scrollTrigger: {
          trigger: item,
          start: 'top 95%',
          end: 'bottom 75%',
          scrub: true,
        },
      }
    );
  });
}
